import { flow, forEach, get, reject, unary } from 'lodash/fp';
import { gql } from '@apollo/client';
import getMelbourneTimezoneMoment from '@ahmdigital/logic/lib/utils/get-melbourne-timezone-moment';
// @ts-expect-error - Automatic, Please fix when editing this file
import isExpired from '@ahmdigital/offers/lib/model/offer/is-expired';

import { GRAPHQL } from '../../../../cms/constants';
import howToFields from '../../../../cms/fragments/how-to-fields';
import logging from '../../../../logging';
import useOfferQuery from '../use-offer-query';
import validateOffer from '../validate-offer';

const ORGANISATION_FIELDS = `
  offeredByOrganization {
    name
    identifier {
      current
    }
    logo {
      ${GRAPHQL.IMAGE_FIELDS}
    }
    termsAndConditions:termsAndConditionsRaw
    isFulfilledUsingSourceCodes {
      appliesToServiceChannel {
        name
      }
      code {
        current
      }
    }
  }
`;

const HOW_TO_FIELDS = `
  howTo {
    ...howToFields
  }
`;

// @ts-expect-error - Automatic, Please fix when editing this file
const generateOffersQuery = ({ filter, withOrganisation = false, withHowTo = false } = {}) => gql`
  query AllOffer {
    allOffer${filter ? `(where: ${filter})` : ''} {
      _id
      _type
      id: _id
      _createdAt
      ${GRAPHQL.RELEASE}
      canBeRedeemedWithPromoCode {
        code {
          current
        }
        codeType
        description: descriptionRaw
      }
      minimumGapInCoverDays
      detailsTeaser
      targetsSegments {
        identifier {
          current
        }
      }
      identifier {
        current
      }
      ${withHowTo ? HOW_TO_FIELDS : ''}
      offerTypes {
        _id
        name
        identifier {
          current
        }
        slogan: sloganRaw
        description: descriptionRaw
        givesAccessToOneOfRewards {
          identifier {
            current
          }
          name
        }
        fulfilledAfterDays
        ${GRAPHQL.OFFER_TYPE_CONDITIONS}
        ${withOrganisation ? ORGANISATION_FIELDS : ''}
        ${GRAPHQL.IMAGE_OBJECT}
        summary
      }
    }
  }

  ${withHowTo ? howToFields : ''}
`;

const useGetAllOffers = ({ skip = false, ...opts } = {}) => {
  // @ts-expect-error - Automatic, Please fix when editing this file
  const { data, error, loading } = useOfferQuery(generateOffersQuery(opts), { skip });

  if (skip) {
    return { allOffers: [], loading: false };
  }

  const allOffers = get('allOffer', data);

  if (!loading && error) {
    logging.getLogger().error('graphql::error - Failed to get offers from CMS', { error, opts });
    return { allOffers: [], loading: false };
  }

  // Validate all offers but don't bother for offers that are out of date
  const now = getMelbourneTimezoneMoment();

  // eslint-disable-next-line lodash-fp/no-unused-result
  flow(
    reject((offer) => isExpired(offer, { effectiveDate: now })),
    forEach(unary(validateOffer)),
  )(allOffers);

  return { allOffers, loading };
};

export default useGetAllOffers;

export { generateOffersQuery };
